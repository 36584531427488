<template>
  <b-embed
    type="iframe"
    :src="link"
    allowfullscreen
  />
</template>

<script>
import { BEmbed } from 'bootstrap-vue'

export default {
  components: {
    BEmbed,
  },

  props: {
    code: {
      type: String,
      required: true,
    },
  },

  computed: {
    link() {
      return `https://app.powerbi.com/view?r=${this.code}`
    },
  },
}
</script>
